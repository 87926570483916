// import avatarImg from '../../assets/img/team/1.jpg';
// import avatarImg from '../../assets/img/team/2.jpg';
// import avatarImg from '../../assets/img/team/3.jpg';
// import avatarImg from '../../assets/img/team/4.jpg';
// import avatarImg from '../../assets/img/team/5.jpg';
// import avatarImg from '../../assets/img/team/6.jpg';

import avatarImg from '../../assets/img/team/avatar.png';

export default [
  {
    id: 1,
    name: 'Emma Watson',
    avatar: {
      src: avatarImg,
      size: '2xl',
      status: 'online'
    },
    role: 'Admin'
  },
  {
    id: 2,
    name: 'Antony Hopkins',
    avatar: {
      src: avatarImg,
      size: '2xl',
      status: 'online'
    },
    role: 'Moderator'
  },
  {
    id: 3,
    name: 'Anna Karinina',
    avatar: {
      src: avatarImg,
      size: '2xl',
      status: 'away'
    },
    role: 'Editor'
  },
  {
    id: 4,
    name: 'John Lee',
    avatar: {
      src: avatarImg,
      size: '2xl',
      status: 'offline'
    },
    role: 'Admin'
  },
  {
    id: 5,
    name: 'Rowen Atkinson',
    avatar: {
      src: avatarImg,
      size: '2xl',
      status: 'offline'
    },
    role: 'Editor'
  },
  {
    id: 6,
    name: 'Isaac Hempstead',
    avatar: {
      src: avatarImg,
      size: '2xl',
      status: 'offline'
    },
    role: 'Moderator'
  }
];
